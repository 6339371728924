// src/pages/HomePage.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../theme/ThemeContext';
import { Container, Typography, Grid, Card, Box, Chip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

// Mock data for popular content
const mockPopularContent = [
  { 
    id: "1", 
    title: "Apple's New Product Line Shows Promise", 
    ticker: "AAPL",
    preview: "The tech giant unveiled its latest innovations yesterday, with analysts predicting strong market reception.",
    author: "TechAnalyst",
    time: "2h ago",
    type: "news",
    upvotes: 214,
    comments: 31
  },
  { 
    id: "2", 
    title: "Tesla Q1 Earnings Beat Expectations", 
    ticker: "TSLA",
    preview: "Tesla reported Q1 earnings exceeding Wall Street expectations by 15%, driven by strong delivery numbers and improved margins.",
    author: "EVInvestor",
    time: "5h ago",
    type: "dd",
    upvotes: 178,
    comments: 27
  },
  { 
    id: "3", 
    title: "Microsoft Cloud Revenue Soars", 
    ticker: "MSFT",
    preview: "Microsoft's cloud business grew 30% year-over-year, cementing its position as a leader in the enterprise space.",
    author: "CloudWatcher",
    time: "1d ago",
    type: "news",
    upvotes: 165,
    comments: 18
  },
  { 
    id: "4", 
    title: "Google's AI Integration Strategy - Deep Dive", 
    ticker: "GOOGL",
    preview: "A comprehensive analysis of Google's AI integration across its product ecosystem and the long-term revenue implications.",
    author: "AIAnalyst",
    time: "2d ago",
    type: "dd",
    upvotes: 142,
    comments: 22
  },
  { 
    id: "5", 
    title: "NVIDIA's Datacenter Growth - Industry Analysis", 
    ticker: "NVDA",
    preview: "Breaking down NVIDIA's datacenter segment growth and competitive positioning in the AI chip market.",
    author: "ChipInsider",
    time: "1d ago",
    type: "dd",
    upvotes: 156,
    comments: 24
  },
  { 
    id: "6", 
    title: "Amazon AWS vs Azure vs GCP - Market Share Analysis", 
    ticker: "AMZN, MSFT, GOOGL",
    preview: "Detailed comparison of cloud providers' market share trends and growth trajectories based on recent earnings.",
    author: "CloudExpert",
    time: "3d ago",
    type: "dd",
    upvotes: 132,
    comments: 19
  },
];

function HomePage() {
  const { themeColors } = useContext(ThemeContext);
  
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Popular Content Section */}
      <Typography 
        variant="h4" 
        component="h1" 
        sx={{ 
          fontWeight: 'bold', 
          mb: 3, 
          color: themeColors.text.primary 
        }}
      >
        Popular Content
      </Typography>
      
      <Grid container spacing={2}>
        {mockPopularContent.map(item => (
          <Grid item xs={12} key={item.id}>
            <ContentCard item={item} themeColors={themeColors} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

// Content Card Component
const ContentCard = ({ item, themeColors }) => {
  return (
    <Card
      component={Link}
      to={`/stock/${item.ticker.split(',')[0].trim()}`}
      sx={{
        display: 'block',
        p: 2,
        backgroundColor: themeColors.card.background,
        textDecoration: 'none',
        '&:hover': { backgroundColor: themeColors.background.tertiary }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Chip 
          label={item.type === 'dd' ? 'DD' : 'NEWS'} 
          size="small"
          sx={{ 
            backgroundColor: item.type === 'dd' ? themeColors.button.primary : themeColors.button.warning,
            color: themeColors.text.inverse,
            fontWeight: 'bold',
            fontSize: '0.7rem'
          }}
        />
        <Typography variant="caption" color={themeColors.text.tertiary}>
          {item.time}
        </Typography>
      </Box>
      
      <Typography 
        variant="h6" 
        sx={{ 
          mb: 1, 
          color: themeColors.text.primary,
          fontWeight: 'bold'
        }}
      >
        {item.title}
      </Typography>
      
      <Typography 
        variant="body2" 
        sx={{ 
          mb: 2, 
          color: themeColors.text.secondary
        }}
      >
        {item.preview}
      </Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography 
          variant="body2" 
          sx={{ 
            fontWeight: 'bold', 
            color: themeColors.button.primary 
          }}
        >
          {item.ticker}
        </Typography>
        
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography 
            variant="body2" 
            sx={{ color: themeColors.text.tertiary, mr: 2 }}
          >
            by {item.author}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
            <ArrowUpwardIcon 
              sx={{ 
                color: themeColors.button.success, 
                fontSize: '0.9rem', 
                mr: 0.5 
              }} 
            />
            <Typography variant="body2" color={themeColors.text.tertiary}>
              {item.upvotes}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ChatBubbleOutlineIcon 
              sx={{ 
                color: themeColors.text.tertiary, 
                fontSize: '0.9rem', 
                mr: 0.5 
              }} 
            />
            <Typography variant="body2" color={themeColors.text.tertiary}>
              {item.comments}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default HomePage;
