import React, { useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import "./AuthPage.css"; // Import the CSS for styling

function AuthPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const { login, signup } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await login(email, password);
      //   alert("Logged in successfully!");
      navigate("/"); // Redirect to HomePage after successful login
    } catch (error) {
      //   alert(`Failed to log in: ${error.message}`);
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    try {
      await signup(email, password);
      //   alert("Registered and logged in successfully!");
      navigate("/"); // Redirect to HomePage after successful registration
    } catch (error) {
      //   alert(`Failed to register: ${error.message}`);
    }
  };

  const toggleMode = (event) => {
    event.preventDefault(); // Prevent form submission
    setIsLoggingIn(!isLoggingIn);
  };

  return (
    <div className="auth-container">
      <form
        className="auth-form"
        onSubmit={isLoggingIn ? handleLogin : handleRegister}
      >
        <h1>{isLoggingIn ? "Login" : "Register"}</h1>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">{isLoggingIn ? "Login" : "Register"}</button>
        <button
          type="button" // Set type to 'button' to avoid unwanted form submission
          className="auth-switch"
          onClick={toggleMode}
        >
          {isLoggingIn ? "Need to register?" : "Already have an account?"}
        </button>
      </form>
    </div>
  );
}

export default AuthPage;
