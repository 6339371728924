import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { ThemeContext } from "../theme/ThemeContext";
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  TextField, 
  Box,
  InputAdornment,
  Container,
  Switch,
  useMediaQuery
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const Navigation = () => {
  const { currentUser, logout } = useContext(AuthContext);
  const { theme, themeColors, toggleTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleLogout = async () => {
    try {
      await logout();
      // Optionally add redirection or a message here
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (search.trim()) {
      navigate(`/stock/${search.toUpperCase()}`);
      setSearch(""); // Clear the search input after navigating
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: themeColors.background.primary, color: themeColors.text.primary }}>
      <Container maxWidth="lg">
        <Toolbar sx={{ justifyContent: 'space-between', padding: isMobile ? 1 : 2 }}>
          {/* Logo */}
          <Typography 
            variant="h5" 
            component={Link} 
            to="/" 
            sx={{ 
              fontWeight: 'bold',
              textDecoration: 'none',
              color: themeColors.text.primary
            }}
          >
            DD Dump
          </Typography>
          
          {/* Navigation Links and Search */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {!isMobile && (
              <>
                <Button 
                  component={Link} 
                  to="/" 
                  sx={{ color: themeColors.text.primary }}
                >
                  Home
                </Button>
                <Button 
                  component={Link} 
                  to="/search" 
                  sx={{ color: themeColors.text.primary }}
                >
                  Stocks
                </Button>
              </>
            )}
            
            {/* Search Field */}
            <form onSubmit={handleSearchSubmit}>
              <TextField
                size={isMobile ? "small" : "medium"}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                  sx: { 
                    backgroundColor: themeColors.background.secondary,
                    borderRadius: 1,
                    width: isMobile ? 120 : 200,
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'transparent'
                    }
                  }
                }}
                sx={{ mr: 1 }}
              />
            </form>
            
            {/* Theme Toggle */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LightModeIcon sx={{ 
                color: theme === 'light' ? themeColors.button.primary : themeColors.text.tertiary,
                mr: 0.5
              }} />
              <Switch 
                checked={theme === 'dark'}
                onChange={toggleTheme}
                color="primary"
                size="small"
              />
              <DarkModeIcon sx={{ 
                color: theme === 'dark' ? themeColors.button.primary : themeColors.text.tertiary,
                ml: 0.5
              }} />
            </Box>
            
            {/* Auth Links */}
            {currentUser ? (
              <>
                <Button 
                  component={Link} 
                  to="/profile" 
                  sx={{ color: themeColors.text.primary, display: isMobile ? 'none' : 'block' }}
                >
                  Profile
                </Button>
                <Button 
                  onClick={handleLogout}
                  variant="outlined"
                  color="primary"
                  size={isMobile ? "small" : "medium"}
                >
                  Logout
                </Button>
              </>
            ) : (
              <Button 
                component={Link} 
                to="/auth"
                variant="contained"
                color="primary"
                size={isMobile ? "small" : "medium"}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
