// src/pages/UserProfile.js
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { ThemeContext } from "../theme/ThemeContext";
import { 
  Container, 
  Typography, 
  Box, 
  Paper, 
  Avatar, 
  Divider, 
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import BookmarkIcon from "@mui/icons-material/Bookmark";

function UserProfile() {
  const { username } = useParams();
  const { currentUser } = useContext(AuthContext);
  const { themeColors } = useContext(ThemeContext);

  // Mock data for user submissions
  const mockSubmissions = [
    {
      id: 1,
      title: "Apple Q1 Results Analysis",
      ticker: "AAPL",
      date: "2023-05-15",
      upvotes: 45
    },
    {
      id: 2,
      title: "Tesla Production Capacity Review",
      ticker: "TSLA",
      date: "2023-04-22",
      upvotes: 32
    },
    {
      id: 3,
      title: "Microsoft's AI Strategy",
      ticker: "MSFT",
      date: "2023-03-10",
      upvotes: 28
    }
  ];

  // Mock data for favorite stocks
  const mockFavorites = [
    { ticker: "AAPL", name: "Apple Inc." },
    { ticker: "MSFT", name: "Microsoft Corporation" },
    { ticker: "TSLA", name: "Tesla Inc." },
    { ticker: "NVDA", name: "NVIDIA Corporation" }
  ];

  const displayName = username || (currentUser ? currentUser.email : "Guest");
  const userInitial = displayName ? displayName.charAt(0).toUpperCase() : "?";
  
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* User Info Card */}
      <Paper 
        elevation={1}
        sx={{ 
          p: 3, 
          mb: 4, 
          backgroundColor: themeColors.card.background,
          borderRadius: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Avatar 
            sx={{ 
              width: 80, 
              height: 80, 
              bgcolor: themeColors.button.primary,
              color: themeColors.text.inverse,
              fontSize: 32,
              mr: 3
            }}
          >
            {userInitial}
          </Avatar>
          <Box>
            <Typography variant="h4" component="h1" color={themeColors.text.primary}>
              {displayName}
            </Typography>
            <Typography variant="body1" color={themeColors.text.secondary}>
              {currentUser ? currentUser.email : ""}
            </Typography>
          </Box>
        </Box>
        
        <Divider sx={{ my: 2 }} />
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="body2" color={themeColors.text.secondary}>
              Member since
            </Typography>
            <Typography variant="body1" color={themeColors.text.primary}>
              {currentUser ? "January 2023" : "-"}
            </Typography>
          </Box>
          
          <Box>
            <Typography variant="body2" color={themeColors.text.secondary}>
              Submissions
            </Typography>
            <Typography variant="body1" color={themeColors.text.primary}>
              {mockSubmissions.length}
            </Typography>
          </Box>
          
          <Box>
            <Typography variant="body2" color={themeColors.text.secondary}>
              Favorite Stocks
            </Typography>
            <Typography variant="body1" color={themeColors.text.primary}>
              {mockFavorites.length}
            </Typography>
          </Box>
        </Box>
      </Paper>
      
      {/* Content Grid */}
      <Grid container spacing={4}>
        {/* Left Column - Submissions */}
        <Grid item xs={12} md={7}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: themeColors.text.primary }}>
            Your Submissions
          </Typography>
          
          {mockSubmissions.length > 0 ? (
            <List sx={{ bgcolor: themeColors.card.background, borderRadius: 2 }}>
              {mockSubmissions.map((submission) => (
                <React.Fragment key={submission.id}>
                  <ListItem>
                    <ListItemIcon>
                      <DescriptionIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                      primary={
                        <Typography sx={{ color: themeColors.text.primary }}>
                          {submission.title}
                        </Typography>
                      }
                      secondary={
                        <Box sx={{ color: themeColors.text.tertiary, fontSize: '0.875rem' }}>
                          <Box component="span" sx={{ color: themeColors.button.primary, fontWeight: 'bold', mr: 1 }}>
                            {submission.ticker}
                          </Box>
                          <Box component="span" sx={{ mr: 1 }}>
                            {submission.date}
                          </Box>
                          <Box component="span">
                            {submission.upvotes} upvotes
                          </Box>
                        </Box>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Paper
              sx={{ 
                p: 3, 
                textAlign: 'center',
                backgroundColor: themeColors.card.background,
                borderRadius: 2
              }}
            >
              <Typography color={themeColors.text.tertiary}>
                You haven't made any submissions yet.
              </Typography>
              <Button 
                variant="contained" 
                color="primary"
                sx={{ mt: 2 }}
              >
                Create your first submission
              </Button>
            </Paper>
          )}
        </Grid>
        
        {/* Right Column - Favorite Stocks */}
        <Grid item xs={12} md={5}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: themeColors.text.primary }}>
            Favorite Stocks
          </Typography>
          
          {mockFavorites.length > 0 ? (
            <List sx={{ bgcolor: themeColors.card.background, borderRadius: 2 }}>
              {mockFavorites.map((stock, index) => (
                <React.Fragment key={stock.ticker}>
                  <ListItem
                    secondaryAction={
                      <BookmarkIcon color="primary" />
                    }
                  >
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText 
                      primary={
                        <Typography sx={{ color: themeColors.text.primary, fontWeight: 'bold' }}>
                          {stock.ticker}
                        </Typography>
                      }
                      secondary={
                        <Typography sx={{ color: themeColors.text.tertiary }}>
                          {stock.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {index < mockFavorites.length - 1 && (
                    <Divider variant="inset" component="li" />
                  )}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Paper
              sx={{ 
                p: 3, 
                textAlign: 'center',
                backgroundColor: themeColors.card.background,
                borderRadius: 2
              }}
            >
              <Typography color={themeColors.text.tertiary}>
                You haven't added any favorite stocks yet.
              </Typography>
              <Button 
                variant="contained" 
                color="primary"
                sx={{ mt: 2 }}
              >
                Browse stocks
              </Button>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default UserProfile;
