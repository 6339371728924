import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../theme/ThemeContext';
import { Grid, TextField, InputAdornment, Card, Typography, Box, Container, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

// Mock data with all tickers preloaded
const mockTickers = [
  { id: "1", ticker: "AAPL", name: "Apple Inc." },
  { id: "2", ticker: "GOOGL", name: "Alphabet Inc." },
  { id: "3", ticker: "AMZN", name: "Amazon.com Inc." },
  { id: "4", ticker: "MSFT", name: "Microsoft Corporation" },
  { id: "5", ticker: "TSLA", name: "Tesla Inc." },
  { id: "6", ticker: "META", name: "Meta Platforms Inc." },
  { id: "7", ticker: "NFLX", name: "Netflix Inc." },
  { id: "8", ticker: "NVDA", name: "NVIDIA Corporation" },
  { id: "9", ticker: "JPM", name: "JPMorgan Chase & Co." },
  { id: "10", ticker: "V", name: "Visa Inc." },
];

// Mock data for moving stocks and favorite stocks
const mockMovingStocks = [
  { id: "1", ticker: "AAPL", name: "Apple Inc.", price: "$175.50", change: "+1.25%" },
  { id: "2", ticker: "GOOGL", name: "Alphabet Inc.", price: "$140.75", change: "-0.85%" },
  { id: "3", ticker: "AMZN", name: "Amazon.com Inc.", price: "$110.30", change: "+0.65%" },
  { id: "4", ticker: "MSFT", name: "Microsoft Corp.", price: "$389.50", change: "+0.92%" },
  { id: "5", ticker: "NVDA", name: "NVIDIA Corp.", price: "$860.30", change: "-1.20%" },
];

const mockFavoriteStocks = [
  { id: "5", ticker: "TSLA", name: "Tesla Inc.", price: "$200.50", change: "+2.10%" },
  { id: "6", ticker: "META", name: "Meta Platforms Inc.", price: "$300.75", change: "-0.50%" },
];

const SearchPage = () => {
  const { themeColors } = useContext(ThemeContext);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [recentSearches, setRecentSearches] = useState([
    { id: "1", ticker: "AAPL", name: "Apple Inc." },
    { id: "2", ticker: "GOOGL", name: "Alphabet Inc." },
    { id: "5", ticker: "TSLA", name: "Tesla Inc." },
    { id: "6", ticker: "META", name: "Meta Platforms Inc." },
    { id: "7", ticker: "NFLX", name: "Netflix Inc." },
    { id: "8", ticker: "NVDA", name: "NVIDIA Corporation" },
    { id: "9", ticker: "JPM", name: "JPMorgan Chase & Co." },
    { id: "10", ticker: "V", name: "Visa Inc." },
  ]);

  // Handle search functionality
  const handleSearch = (e) => {
    const searchText = e.target.value;
    setQuery(searchText);
    
    if (!searchText) {
      setResults([]);
      return;
    }
    
    const filteredResults = mockTickers.filter(item =>
      item.ticker.toLowerCase().includes(searchText.toLowerCase()) ||
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
    
    setResults(filteredResults);
  };

  // Handle selecting a stock
  const handleSelectStock = (ticker) => {
    // Add to recent searches
    const selectedStock = mockTickers.find(stock => stock.ticker === ticker);
    if (selectedStock) {
      // Remove if already in recent searches
      const updatedRecents = recentSearches.filter(item => item.ticker !== ticker);
      // Add to the top of the list
      setRecentSearches([selectedStock, ...updatedRecents.slice(0, 7)]);
    }
  };

  // Store recent searches in localStorage
  useEffect(() => {
    const storedSearches = localStorage.getItem('recentSearches');
    if (storedSearches) {
      setRecentSearches(JSON.parse(storedSearches));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
  }, [recentSearches]);

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Search Bar */}
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          placeholder="Search stocks by ticker or name"
          value={query}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx: {
              backgroundColor: themeColors.background.secondary,
              borderRadius: 2,
              p: 1,
            }
          }}
        />
      </Box>

      {query ? (
        <Box>
          <Typography variant="h6" sx={{ mb: 2, color: themeColors.text.secondary }}>Results</Typography>
          
          {results.length > 0 ? (
            <Grid container spacing={2}>
              {results.map(item => (
                <Grid item xs={12} key={item.id}>
                  <Card 
                    component={Link} 
                    to={`/stock/${item.ticker}`}
                    onClick={() => handleSelectStock(item.ticker)}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      textDecoration: 'none',
                      p: 2,
                      backgroundColor: themeColors.card.background,
                      '&:hover': { 
                        backgroundColor: themeColors.background.tertiary 
                      }
                    }}
                  >
                    <Box>
                      <Typography variant="h6" color={themeColors.text.primary}>{item.ticker}</Typography>
                      <Typography variant="body2" color={themeColors.text.tertiary}>{item.name}</Typography>
                    </Box>
                    <TrendingUpIcon color="primary" />
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography align="center" color={themeColors.text.tertiary} sx={{ mt: 4 }}>
              No results found. Try another search.
            </Typography>
          )}
        </Box>
      ) : (
        <>
          {/* Recent Searches - 3x3 Grid */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Recent Searches</Typography>
            
            {recentSearches.length > 0 ? (
              <Grid container spacing={1}>
                {recentSearches.slice(0, 9).map(item => (
                  <Grid item xs={4} key={item.id}>
                    <Paper
                      component={Link}
                      to={`/stock/${item.ticker}`}
                      onClick={() => handleSelectStock(item.ticker)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textDecoration: 'none',
                        height: 70,
                        p: 1,
                        backgroundColor: themeColors.card.background,
                        border: `1px solid ${themeColors.border.light}`,
                        borderRadius: 1,
                        '&:hover': { 
                          backgroundColor: themeColors.background.tertiary 
                        }
                      }}
                    >
                      <Typography variant="h6" color={themeColors.text.primary} align="center">
                        {item.ticker}
                      </Typography>
                      <Typography 
                        variant="caption" 
                        color={themeColors.text.tertiary} 
                        align="center"
                        noWrap
                        sx={{ maxWidth: '100%' }}
                      >
                        {item.name}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography align="center" color={themeColors.text.tertiary}>
                No recent searches.
              </Typography>
            )}
          </Box>
          
          {/* Favorite Stocks */}
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Favorite Stocks</Typography>
            
            {mockFavoriteStocks.length > 0 ? (
              <Grid container spacing={2}>
                {mockFavoriteStocks.map(item => (
                  <Grid item xs={12} key={item.id}>
                    <StockCard 
                      stock={item} 
                      themeColors={themeColors} 
                      onSelect={() => handleSelectStock(item.ticker)}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography align="center" color={themeColors.text.tertiary}>
                No favorite stocks yet.
              </Typography>
            )}
          </Box>
          
          {/* Moving Stocks */}
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>Moving Stocks</Typography>
            
            <Grid container spacing={2}>
              {mockMovingStocks.map(item => (
                <Grid item xs={12} key={item.id}>
                  <StockCard 
                    stock={item} 
                    themeColors={themeColors} 
                    onSelect={() => handleSelectStock(item.ticker)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
    </Container>
  );
};

// Stock Card Component
const StockCard = ({ stock, themeColors, onSelect }) => {
  return (
    <Card 
      component={Link} 
      to={`/stock/${stock.ticker}`}
      onClick={onSelect}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        textDecoration: 'none',
        p: 2,
        backgroundColor: themeColors.card.background,
        '&:hover': { 
          backgroundColor: themeColors.background.tertiary 
        }
      }}
    >
      <Box>
        <Typography variant="h6" color={themeColors.text.primary}>{stock.ticker}</Typography>
        <Typography variant="body2" color={themeColors.text.tertiary}>{stock.name}</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Typography variant="body1" color={themeColors.text.primary}>{stock.price}</Typography>
        <Typography 
          variant="body2" 
          color={stock.change.includes('+') ? themeColors.button.success : themeColors.button.danger}
        >
          {stock.change}
        </Typography>
      </Box>
    </Card>
  );
};

export default SearchPage;