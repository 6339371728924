import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ThemeContext } from '../theme/ThemeContext';
import {
  doc,
  getDoc,
  getDocs,
  addDoc,
  collection,
  where,
} from "firebase/firestore";
import { db } from "../utils/firebase";
import { AdvancedChart } from "react-tradingview-embed";
import {
  Container,
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Card,
  Tabs,
  Tab,
  Divider,
  Grid,
  Paper,
} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

// Mock data for stock details when Firestore isn't available
const mockStockDetails = {
  AAPL: {
    name: "Apple Inc.",
    price: "$175.50",
    change: "+1.25%",
    summary: "Apple Inc. designs, manufactures, and markets smartphones, personal computers, tablets, wearables, and accessories worldwide. The company offers iPhone, Mac, iPad, and Wearables, Home, and Accessories. The company operates in the Americas, Europe, Greater China, Japan, and Rest of Asia Pacific regions.",
    submissions: [
      {
        id: "a1",
        title: "Apple's Q1 2023 Analysis",
        content: "Apple reported strong Q1 2023 results with iPhone revenue growing by 8% year-over-year. The Services segment continues to show impressive growth, now accounting for 21% of total revenue with higher profit margins than hardware. Their cash position remains strong at $63B, suggesting potential for increased dividends or share buybacks.",
        author: "TechAnalyst",
        time: "2d ago",
        type: "dd",
        upvotes: 214,
        downvotes: 8,
        comments: 31,
        sources: [
          "https://investor.apple.com/investor-relations/default.aspx",
          "https://www.sec.gov/Archives/edgar/data/320193/000032019323000006/aapl-20221231.htm"
        ]
      },
    ]
  },
};

function StockDetail() {
  const { ticker } = useParams();
  const { themeColors, theme } = useContext(ThemeContext);
  const [summary, setSummary] = useState("");
  const [ddList, setDdList] = useState([]);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [stockInfo, setStockInfo] = useState({
    name: "",
    price: "",
    change: ""
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const docRef = doc(db, "stocks", ticker);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setSummary(docSnap.data().summary);
          setStockInfo({
            name: docSnap.data().name || "",
            price: docSnap.data().price || "",
            change: docSnap.data().change || ""
          });
        } else {
          console.log("No such document!");
          // Use mock data if Firestore doesn't have the stock
          if (mockStockDetails[ticker]) {
            setSummary(mockStockDetails[ticker].summary);
            setStockInfo({
              name: mockStockDetails[ticker].name,
              price: mockStockDetails[ticker].price,
              change: mockStockDetails[ticker].change
            });
            setDdList(mockStockDetails[ticker].submissions || []);
          }
        }

        const querySnapshot = await getDocs(
          collection(db, "dd"),
          where("ticker", "==", ticker)
        );
        if (!querySnapshot.empty) {
          setDdList(
            querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Use mock data if there's an error with Firestore
        if (mockStockDetails[ticker]) {
          setSummary(mockStockDetails[ticker].summary);
          setStockInfo({
            name: mockStockDetails[ticker].name,
            price: mockStockDetails[ticker].price,
            change: mockStockDetails[ticker].change
          });
          setDdList(mockStockDetails[ticker].submissions || []);
        }
      }
    }

    fetchData();
  }, [ticker]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const handleSubmitDD = async (event) => {
    event.preventDefault();
    try {
      await addDoc(collection(db, "dd"), {
        ticker: ticker,
        title: event.target.title.value,
        content: event.target.content.value,
        type: "dd",
        author: "User", // Replace with actual user info
        createdAt: new Date(),
        upvotes: 0,
        downvotes: 0,
        comments: 0
      });
      handleClose();
      // Refetch DD list
      const querySnapshot = await getDocs(
        collection(db, "dd"),
        where("ticker", "==", ticker)
      );
      setDdList(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Stock Header */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
          <Box>
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: themeColors.text.primary }}>
              {ticker.toUpperCase()}
            </Typography>
            <Typography variant="subtitle1" color={themeColors.text.secondary}>
              {stockInfo.name}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Typography variant="h5" color={themeColors.text.primary}>
              {stockInfo.price}
            </Typography>
            <Typography 
              variant="subtitle1" 
              color={stockInfo.change && stockInfo.change.includes('+') ? themeColors.button.success : themeColors.button.danger}
            >
              {stockInfo.change}
            </Typography>
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', mb: 3 }}>
          <Button
            variant="outlined"
            startIcon={isFavorite ? <BookmarkIcon /> : <BookmarkBorderIcon />}
            onClick={toggleFavorite}
            sx={{ mr: 2 }}
          >
            {isFavorite ? 'Favorited' : 'Favorite'}
          </Button>
          
          <Button
            variant="outlined"
            startIcon={<ShareIcon />}
          >
            Share
          </Button>
          
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            sx={{ ml: 'auto' }}
          >
            Add Due Diligence
          </Button>
        </Box>

        {/* Stock Chart */}
        <Box sx={{ mb: 3, height: 500, backgroundColor: themeColors.card.background, borderRadius: 1 }}>
          <AdvancedChart
            widgetConfig={{
              symbol: ticker,
              width: "100%",
              height: "500px",
              locale: "en",
              theme: theme === 'dark' ? 'dark' : 'light',
            }}
          />
        </Box>
        
        {/* Stock Summary */}
        <Paper sx={{ p: 3, backgroundColor: themeColors.card.background }}>
          <Typography variant="h6" sx={{ mb: 2, color: themeColors.text.primary }}>
            Company Summary
          </Typography>
          <Typography variant="body1" color={themeColors.text.primary}>
            {summary}
          </Typography>
        </Paper>
      </Box>
      
      {/* Content Tabs */}
      <Box>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{ mb: 2 }}
        >
          <Tab label="AI Summary" />
          <Tab label="Submissions" />
        </Tabs>
        
        <Divider sx={{ mb: 3 }} />
        
        {/* Tab Content */}
        <Box>
          {activeTab === 0 ? (
            <Box>
              <Typography variant="h6" color={themeColors.text.primary} sx={{ mb: 2 }}>
                AI Generated Summary
              </Typography>
              <Paper sx={{ p: 3, backgroundColor: themeColors.card.background }}>
                <Typography variant="body1" color={themeColors.text.primary}>
                  {summary ? summary : "No AI summary available yet. Add some due diligence to get started!"}
                </Typography>
              </Paper>
            </Box>
          ) : (
            <Box>
              <Typography variant="h6" color={themeColors.text.primary} sx={{ mb: 2 }}>
                Due Diligence Submissions
              </Typography>
              
              {ddList.length > 0 ? (
                <Grid container spacing={3}>
                  {ddList.map((submission) => (
                    <Grid item xs={12} key={submission.id}>
                      <Card sx={{ p: 3, backgroundColor: themeColors.card.background }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box 
                              sx={{ 
                                backgroundColor: themeColors.button.primary,
                                color: themeColors.text.inverse,
                                px: 1,
                                py: 0.5,
                                borderRadius: 1,
                                fontSize: '0.7rem',
                                fontWeight: 'bold',
                                mr: 2
                              }}
                            >
                              DD
                            </Box>
                            <Typography variant="body2" color={themeColors.text.tertiary}>
                              {submission.createdAt ? new Date(submission.createdAt.seconds * 1000).toDateString() : "Unknown date"} by {submission.author || "Anonymous"}
                            </Typography>
                          </Box>
                        </Box>
                        
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: themeColors.text.primary }}>
                          {submission.title || (submission.content && submission.content.substring(0, 30) + "...")}
                        </Typography>
                        
                        <Typography variant="body1" sx={{ mb: 3, color: themeColors.text.primary }}>
                          {submission.content}
                        </Typography>
                        
                        {submission.sources && submission.sources.length > 0 && (
                          <Box sx={{ mb: 3 }}>
                            <Typography variant="subtitle2" sx={{ mb: 1, color: themeColors.text.secondary }}>
                              Sources:
                            </Typography>
                            {submission.sources.map((source, index) => (
                              <Typography 
                                key={index} 
                                variant="body2" 
                                component="a" 
                                href={source} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                sx={{ 
                                  display: 'block', 
                                  color: themeColors.button.primary,
                                  mb: 0.5
                                }}
                              >
                                {source}
                              </Typography>
                            ))}
                          </Box>
                        )}
                        
                        <Divider sx={{ my: 2 }} />
                        
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button 
                              startIcon={<ArrowUpwardIcon />} 
                              size="small" 
                              sx={{ color: themeColors.button.success, mr: 1 }}
                            >
                              {submission.upvotes || 0}
                            </Button>
                            <Button 
                              startIcon={<ArrowDownwardIcon />} 
                              size="small" 
                              sx={{ color: themeColors.button.danger, mr: 1 }}
                            >
                              {submission.downvotes || 0}
                            </Button>
                          </Box>
                          
                          <Button 
                            startIcon={<ChatBubbleOutlineIcon />} 
                            size="small" 
                            sx={{ color: themeColors.text.tertiary }}
                          >
                            {submission.comments || 0} Comments
                          </Button>
                        </Box>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography align="center" color={themeColors.text.tertiary} sx={{ py: 4 }}>
                  No submissions yet. Be the first to add due diligence!
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Box>
      
      {/* Submission Modal */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: themeColors.modal.background,
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" sx={{ mb: 3, color: themeColors.text.primary }}>
            Submit New Due Diligence for {ticker.toUpperCase()}
          </Typography>
          <form onSubmit={handleSubmitDD}>
            <TextField
              name="title"
              label="Title"
              fullWidth
              margin="normal"
              variant="outlined"
              required
              sx={{
                mb: 2,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: themeColors.input.border,
                  },
                  '&:hover fieldset': {
                    borderColor: themeColors.input.border,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: themeColors.button.primary,
                  },
                  backgroundColor: themeColors.input.background,
                },
                '& .MuiInputLabel-root': {
                  color: themeColors.text.secondary,
                },
                '& .MuiInputBase-input': {
                  color: themeColors.text.primary,
                }
              }}
            />
            <TextField
              name="content"
              label="Content"
              fullWidth
              multiline
              rows={6}
              margin="normal"
              variant="outlined"
              required
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: themeColors.input.border,
                  },
                  '&:hover fieldset': {
                    borderColor: themeColors.input.border,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: themeColors.button.primary,
                  },
                  backgroundColor: themeColors.input.background,
                },
                '& .MuiInputLabel-root': {
                  color: themeColors.text.secondary,
                },
                '& .MuiInputBase-input': {
                  color: themeColors.text.primary,
                }
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button 
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button 
                type="submit" 
                variant="contained"
                color="primary"
              >
                Submit DD
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Container>
  );
}

export default StockDetail;
