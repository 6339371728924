// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AuthPage from "./pages/AuthPage";
import UserProfile from "./pages/UserProfile";
import Dashboard from "./pages/Dashboard";
import StockDetail from "./pages/StockDetail";
import SearchPage from "./pages/SearchPage";
import { ThemeProvider } from "./theme/ThemeContext";
import { AuthProvider } from "./contexts/AuthContext";
import Navigation from "./components/Nagivation";

function App() {
  return (
    <ThemeProvider>
      <Router>
        <AuthProvider>
          <Navigation />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/user/:username" element={<UserProfile />} />
            <Route path="/stock/:ticker" element={<StockDetail />} />
            <Route path="/search" element={<SearchPage />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
