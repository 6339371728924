// Theme colors for light and dark themes matching the mobile app

export const lightTheme = {
  // Background colors
  background: {
    primary: '#FFFFFF',
    secondary: '#F3F4F6',
    tertiary: '#E5E7EB',
  },
  // Text colors
  text: {
    primary: '#111827',
    secondary: '#374151',
    tertiary: '#6B7280',
    inverse: '#FFFFFF',
  },
  // Button colors
  button: {
    primary: '#2563EB',
    secondary: '#9CA3AF',
    success: '#10B981',
    danger: '#EF4444',
    warning: '#F59E0B',
  },
  // Border colors
  border: {
    light: '#E5E7EB',
    medium: '#D1D5DB',
    dark: '#9CA3AF',
  },
  // Card colors
  card: {
    background: '#F3F4F6',
    shadow: 'rgba(0, 0, 0, 0.1)',
  },
  // Input colors
  input: {
    background: '#FFFFFF',
    border: '#D1D5DB',
    placeholder: '#9CA3AF',
  },
  // Modal colors
  modal: {
    background: '#FFFFFF',
    overlay: 'rgba(0, 0, 0, 0.5)',
  },
};

export const darkTheme = {
  // Background colors
  background: {
    primary: '#111827', // Gray-900
    secondary: '#1F2937', // Gray-800
    tertiary: '#374151', // Gray-700
  },
  // Text colors
  text: {
    primary: '#F9FAFB', // Gray-50
    secondary: '#E5E7EB', // Gray-200
    tertiary: '#9CA3AF', // Gray-400
    inverse: '#111827', // Gray-900
  },
  // Button colors
  button: {
    primary: '#3B82F6', // Blue-500
    secondary: '#6B7280', // Gray-500
    success: '#10B981', // Green-500
    danger: '#EF4444', // Red-500
    warning: '#F59E0B', // Amber-500
  },
  // Border colors
  border: {
    light: '#374151', // Gray-700
    medium: '#4B5563', // Gray-600
    dark: '#6B7280', // Gray-500
  },
  // Card colors
  card: {
    background: '#1F2937', // Gray-800
    shadow: 'rgba(0, 0, 0, 0.3)',
  },
  // Input colors
  input: {
    background: '#374151', // Gray-700
    border: '#4B5563', // Gray-600
    placeholder: '#9CA3AF', // Gray-400
  },
  // Modal colors
  modal: {
    background: '#1F2937', // Gray-800
    overlay: 'rgba(0, 0, 0, 0.75)',
  },
};