// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA3nZ5bZOrkZrK8_S-0PBfNKn56hjpjqR4",
  authDomain: "dddumpcom.firebaseapp.com",
  projectId: "dddumpcom",
  storageBucket: "dddumpcom.firebasestorage.app",
  messagingSenderId: "42311687168",
  appId: "1:42311687168:web:6e2dedd23caa5145ad4ce5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

// Export the necessary Firebase services
export { app, analytics, auth, db };
