import React, { createContext, useState, useEffect, useMemo } from 'react';
import { lightTheme, darkTheme } from './themes';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Create context
export const ThemeContext = createContext();

// Theme provider component
export const ThemeProvider = ({ children }) => {
  // Check if user has a saved theme preference in localStorage
  const getSavedTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light'; // Default to light theme
  };

  // State to hold current theme
  const [theme, setTheme] = useState(getSavedTheme());
  // Get appropriate theme colors based on current theme
  const themeColors = theme === 'dark' ? darkTheme : lightTheme;

  // Create Material UI theme based on our custom theme
  const muiTheme = useMemo(() => 
    createTheme({
      palette: {
        mode: theme,
        primary: {
          main: themeColors.button.primary,
        },
        secondary: {
          main: themeColors.button.secondary,
        },
        error: {
          main: themeColors.button.danger,
        },
        warning: {
          main: themeColors.button.warning,
        },
        success: {
          main: themeColors.button.success,
        },
        background: {
          default: themeColors.background.primary,
          paper: themeColors.card.background,
        },
        text: {
          primary: themeColors.text.primary,
          secondary: themeColors.text.secondary,
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              backgroundColor: themeColors.background.primary,
              color: themeColors.text.primary,
            }
          }
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              backgroundColor: themeColors.background.primary,
              color: themeColors.text.primary,
              boxShadow: theme === 'dark' ? '0 1px 3px rgba(0,0,0,0.3)' : '0 1px 3px rgba(0,0,0,0.1)'
            }
          }
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
            }
          }
        },
        MuiCard: {
          styleOverrides: {
            root: {
              backgroundColor: themeColors.card.background,
            }
          }
        }
      },
    }),
    [theme, themeColors]
  );

  // Toggle theme function
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  // Apply theme to document body - force setting these styles directly for immediate effect
  useEffect(() => {
    document.body.style.backgroundColor = themeColors.background.primary;
    document.body.style.color = themeColors.text.primary;
    
    // Apply to html element as well to ensure full coverage
    document.documentElement.style.backgroundColor = themeColors.background.primary;
    
    // Force all .MuiPaper-root elements to use the correct background
    const paperElements = document.querySelectorAll('.MuiPaper-root');
    paperElements.forEach(el => {
      el.style.backgroundColor = themeColors.card.background;
    });
  }, [theme, themeColors]);

  // Provide theme context to children
  return (
    <ThemeContext.Provider value={{ theme, themeColors, toggleTheme }}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};